<template>
    <div>
        <select-shop :loadingButton="loadingButton" v-on:getData="getData"></select-shop>
        <div v-if="isPermission">
            <CCard class="shadow-sm">
                <CCardBody style="padding: 1.55rem">
                    <CAlert v-if="status === 'SUCCESS'" :color="statusColor" :show.sync="synchSuccessCounter" closeButton >
                       {{ synchStatusText }}  
                        <!-- <CProgress :max="5"  :value="synchSuccessCounter" height="5px"  color="success" animate /> -->
                    </CAlert>
                    <CAlert v-else :color="statusColor" :show.sync="synchSuccessCounter" :closeButton="status === 'fail'" >
                       {{ synchStatusText }} ...
                       <p v-if="status === 'fail'" style="font-size: small;" class="mb-0" >{{statusDescription}}</p>
                        <CProgress v-if="status !== 'fail'" :max="10" :value="synchSuccessCounter" height="10px" :color="statusColor" animate striped />
                    </CAlert>
                    <!-- <CAlert v-if="status === 'fail'" :color="statusColor" :show.sync="synchSuccessCounter" closeButton >
                       {{ synchStatusText }} 
                       <p style="font-size: small;">{{statusDescription}}</p>
                    </CAlert> -->
                    <div class="row mb-3">
                        <div class="col-md-10 col-sm-9 col-8 d-inline-flex align-items-center">
                            <h2 class="font-weight-normal">
                                {{ $t("management.productDeliveryManagement") }}
                            </h2>

                        </div>
                        <div class="col-md-2 col-sm-9 col-4">
                            <CButton block color="warning"  @click="confirmSynchModal = true"   >
                                <CIcon name="cil-sync"  color="light" size="sm" />
                                {{ $t('sync') }}  <img src="/img/delivery/grab.png" class="rounded-circle"
                                style="border-radius: 3px; width: 30px;" />
                                <!-- <p class="mb-0"> {{ $t('lastSync') }}: {{ lastSync() }} </p> -->
                            </CButton>
                        </div>
                    </div>
                    <CAlert id="addSalesHoursInfoAlertBox" color="warning" class="d-inline-block col-12 mt-0 mb-0">
                        <div class="d-flex">
                            <div class="flex-shrink-0">
                                <i class="fi fi-rr-info mr-1 text-warning"></i>
                            </div>
                            <div class="flex-grow-1 ms-3 ml-2">
                                <p class="m-0">{{ $t('deliveryGrab') }}</p>
                                <p class="m-0"> * {{ $t('syncWhenEdit') }}</p>
                                <p class="m-0"> ** {{ $t('syncWhenEditProduct') }}</p>
                                <p class="m-0"> *** {{ $t('lastSync') }}: {{ lastSync() }} </p>

                            </div>
                        </div>
                    </CAlert>
                    <CRow  class="mb-3">
                        <CCol class="col-sm-4">
                            <div class="btn-group btn-group-toggle row view-list mt-2" data-toggle="buttons">
                                <label
                                class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                                style="padding: 4px;"
                                :class="{ active: showView === 'product' }"
                                >
                                <input
                                    type="radio"
                                    name="options"
                                    id="productoption1"
                                    autocomplete="off"
                                    class="custom-control-input cursor"
                                    :value="showView === 'product'"
                                    :active="showView === 'product'"
                                    :checked="this.showView === 'product'"
                                    @change="showView = 'product'"
                                
                                />
                                <div class="mt-1"> {{ $t("product") }}
                                </div>
                                
                                </label>
                                <label
                                class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                                style="padding: 4px;"
                                :class="{ active: showView === 'optional' }"
                                >
                                <input
                                    type="radio"
                                    name="options"
                                    id="optional"
                                    autocomplete="off"
                                    class="custom-control-input cursor"
                                    :value="showView === 'optional'"
                                    :active="showView === 'optional'"
                                    :checked="this.showView === 'optional'"
                                    @change="showView = 'optional'"
                                />
                                <div class="mt-1"> {{ $t("optional") }}
                                </div>
                                </label>
                            </div>
                        </CCol>
                        <CCol class="col-12 col-sm-6 mt-2">
                            <input  
                                v-model="keyword"
                                class="form-control"
                                :placeholder="$t('keyword')"
                                @keyup.enter="getSort(keyword)"
                            />
                            <!-- <input  v-if="showView === 'optional'"
                                v-model="keyword"
                                class="form-control"
                                :placeholder="$t('keyword')"
                                @keyup.enter="getSort(keyword)"
                            /> -->
                        </CCol>
                        <CCol class="col-12 col-sm-2 mt-2">
                            <CButton @click="getSort(keyword)" color="success" block >
                            {{ $t('search') }} 
                            </CButton>                    
                        </CCol>
                    </CRow>
                    <div v-if="showView === 'product'">
                        <CRow>
                            <CCol sm="12" lg="12">
                                <sortdata v-on:getSort="setSort" :sort_value="sort_value" :sortdata="sortdata"
                                    class="mb-3" />
                                    <slot name="over-table" />
                                <div class="mb-3 e table-responsive" style="overflow-x: auto " >
                                    <div class=" position-relative table-responsive">
                                        <table  class="table table-striped"  v-if="data.length > 0">
                                            <thead>
                                                <tr >
                                                    <th style="background-color: #eaecee;"
                                                        class="dark font-weight-normal">{{
                                                            $t('category') }}</th>
                                                    <th style="background-color: #eaecee;"
                                                        class="text-dark font-weight-normal">{{ $t('product') }}</th>
                                                    <th v-for="(item, index) in salesChannel" :key="index" style="background-color: #eaecee;   "  class="text-left font-weight-normal">
                                                        <img :src="item.img" class="rounded-circle"
                                                            style="border-radius: 3px; width: 30px;" />
                                                        {{ item.name }}
                                                    </th>
                                                </tr>
                                            </thead>
                                       
                                            <tbody class="text-dark position-relative"  >
                                                <tr v-for="(item, index) in data" :key="index" v-if="loadingButton">
                                                     
                                                    <td v-if="item.ProductSKU !== undefined"> {{ item.data.ProductSKU.category.name }}
                                                    </td>
                                                    <td v-else>  -
                                                    </td>
                                                    <td >
                                                        <CRow auto class="mr-4" style="width: 210px;" >
                                            
                                                            <CCol lg="3" md="4" v-if="item.ProductPLU !==  undefined">
                                                                <img v-if="item.ProductPLU.remoteImagePath"
                                                                :src="item.ProductPLU.remoteImagePath" class="img-fluid rounded"
                                                                style="border-radius: 3px; width: 60px;" />
                                                                <CButton v-else style="width: 60px;height: 30px;"
                                                                    :style="{ backgroundColor: item.indexColor }"
                                                                    class="img-fluid rounded">
                                                                    <CIcon>
                                                                        <i class="fas fa-square"></i>
                                                                    </CIcon>
                                                                </CButton>
                                                            </CCol>
                                                            <CCol lg="3" md="4" v-else>
                                                                <CButton style="width: 60px;height: 30px;"
                                                                    :style="{ backgroundColor: item.indexColor }"
                                                                    class="img-fluid rounded">
                                                                    <CIcon>
                                                                        <i class="fas fa-square"></i>
                                                                    </CIcon>
                                                                </CButton>
                                                            </CCol>
                                                            <CCol lg="7"  md="8" style="align-content: center;"> 
                                                                <p class="mb-0  name-product " v-if="item.ProductPLU"  >{{ item.ProductPLU.name }}</p>
                                                                <!--   @click="toggleDropdown(item)" -->
                                                                <!-- <CBadge color="dark"   style="font-size: small;" v-if="!item.isEdit">
                                                                    <small class="name-product " v-if="item.sellingTime !== null">
                                                                        <img src="/img/time_EditW.png" class="rounded-circle"
                                                                            style="border-radius: 3px; width: 17px;" />  {{item.sellingTime.name}}
                                                                    </small>
                                                                    <small class="name-product " v-else>
                                                                        <img src="/img/time_EditW.png" class="rounded-circle"
                                                                            style="border-radius: 3px; width: 17px;" />  All Days 
                                                                    </small>
                                                                    
                                                                </CBadge> -->
                                                                <!-- <div  class="row" v-else >
                                                                    <div  class="col-lg-12">
                                                                        <Multiselect id="productPriceSellingTimeSelect" 
                                                                            v-model="item.sellingTime" 
                                                                            @input="toggleDropdown(item)"
                                                                            :options="sellingTimelist" class="cursor "
                                                                            :placeholder="$t('All Days')" label="name" track-by="id"></Multiselect>
                                                                    </div> -->
                                                                    <!-- <CButton color="success" class="col-lg-2"  >
                                                                        <CIcon name="cil-save"  color="success" size="sm" />
                                                                    </CButton>          
                                                                    <CButton color="danger" @click="toggleDropdown(item)" class="col-lg-2 "  >
                                                                        <CIcon name="cil-x"  color="success" size="sm" />
                                                                    </CButton>                  -->
                                                                <!-- </div> -->
                                                            </CCol>
                                                            <CCol lg="2">
                                                                <CButton color="info" size="sm" class="mt-2" @click="showDetailProductEvent(item)">
                                                                    <CIcon name="cil-color-border" size="xl" />
                                                                </CButton>
                                                            </CCol>
                                                        
                                                        </CRow>                                    
                                                    </td>          
                                                    <td v-for="(name, index) in item.delivery"  :key="index" class="text-left" style="">
                                                        <div class="row justify-content-start" v-if="name.isActive">
                                                            <div class="mr-2">
                                                                <CSwitch color="success" :value="name.enabled" v-c-tooltip="{ content: $t('Change status') , placement: 'bottom' }"
                                                                    v-model="name.enabled" :checked.sync="name.enabled" @update:checked="updateAnabledByProduct(name, index)" />
                                                            </div>
                                                            <div class="text-left">
                                                                {{ name.exceedUc }}
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="row justify-content-start">
                                                                <CSwitch color="success" v-c-tooltip="{ content: $t('Not available') , placement: 'bottom' }"  disabled />
                                                            </div>
                                                        </div>
                                                    </td>  
                                                            
                                                </tr>
                                                <tr v-if="!loadingButton ">
                                                    <td :colspan="salesChannel.length +  2">
                                                    <slot name="no-items-view">
                                                        <div class="text-center my-5">
                                                        <h4 style="color: #ced2d8;">
                                                            {{
                                                            data.length ? 'No filtering results ' :  $t('noItem')
                                                            }}
                                                        </h4>
                                                        </div>
                                                    </slot>
                                                    </td>
                                                </tr>
                                             
                                                
                                            </tbody>    
                                        </table>
                                        <table  class="table table-striped" v-else>
                                            <thead>
                                                <tr >
                                                    <th style="background-color: #eaecee;"
                                                        class="dark font-weight-normal">{{
                                                            $t('category') }}</th>
                                                    <th style="background-color: #eaecee;"
                                                        class="text-dark font-weight-normal">{{ $t('product') }}</th>
                                                   
                                                </tr>
                                            </thead>
                                       
                                            <tbody class="text-dark position-relative"  >
                                              
                                                <tr>
                                                    <td :colspan="3">
                                                    <slot name="no-items-view">
                                                        <div class="text-center my-5">
                                                        <h4 style="color: #ced2d8;">
                                                            {{
                                                            data.length ? 'No filtering results ' :  $t('noItem')
                                                            }}
                                                        </h4>
                                                        </div>
                                                    </slot>
                                                    </td>
                                                </tr>
                                             
                                                
                                            </tbody>    
                                        </table>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <pagination :meta_data="meta_data" v-on:next="getProduct"> </pagination>
                    </div>
                    <div v-if="showView === 'optional'">
                        <div class="mb-3" style="overflow-x: auto" >
                            <table  class="table table-striped "  v-if="productoption.length > 0" >
                                <thead>
                                    <tr >
                                        <th style="background-color: #eaecee;"
                                            class="dark font-weight-normal">{{
                                                $t('optional') }}</th>
                                        <th v-for="(item, index) in salesChannel" :key="index" style="background-color: #eaecee; "  class="text-left pl-0">
                                            <img :src="item.img" class="rounded-circle"
                                                style="border-radius: 3px; width: 30px;" />
                                                {{ item.name }}
                                        </th>
                                    </tr>
                                </thead>
                                
                                <tbody class="text-dark " >
                                    <tr v-for="(item, index) in productoption" :key="index">
                                        <td style="padding-left: 10px; " >
                                            {{ item.name }}                                  
                                        </td>          
                                        <td v-for="(name, index) in item.delivery"  :key="index" class="text-left" style=" padding-left: 10px; width: auto;">
                                            <div class="row justify-content-start" >
                                                <div class="mr-2">
                                                    <CSwitch color="success" :value="name.enabled" v-c-tooltip="{ content: $t('Change status') , placement: 'bottom' }"
                                                        v-model="name.enabled" :checked.sync="name.enabled" @update:checked="updateAnabledByObtion(name, item)" />

                                                </div>
                                                <div class="text-left">
                                                    {{ name.price }}
                                                </div>
                                            </div>                                 
                                        </td>       
                                    </tr>
                                </tbody>
                            </table>
                            <table  class="table table-striped" v-else>
                                            <thead>
                                                <tr >
                                                    <th style="background-color: #eaecee;"
                                                        class="dark font-weight-normal">{{
                                                            $t('optional') }}</th>           
                                                </tr>
                                            </thead>
                                       
                                            <tbody class="text-dark position-relative"  > 
                                                <tr>
                                                    <td :colspan="3">
                                                    <slot name="no-items-view">
                                                        <div class="text-center my-5">
                                                        <h4 style="color: #ced2d8;">
                                                            {{
                                                            productoption.length ? 'No filtering results ' :  $t('noItem')
                                                            }}
                                                        </h4>
                                                        </div>
                                                    </slot>
                                                    </td>
                                                </tr>
                                             
                                                
                                            </tbody>    
                            </table>
                        </div>   
                        <pagination :meta_data="meta_data_optional" v-on:next="getProductOption">
                        </pagination>
                    </div>


                </CCardBody>
            </CCard>
            <CRow>
                <modal color="success" :show.sync="confirmSynchModal" :title="$t('confirmSynch')" v-on:isSave="synchronizeMenuwithGrab" centered
                    :alertError="alertError" :loading="loading">
                    <div v-if="alertError">
                    <h4 class="text-center text-danger">{{ textError }}</h4>
                    <br />
                    <h5 class="text-center text-danger">{{ textMessage }}</h5>
                    </div>
                    <div v-else>
                    <br />
                    <h4 class="text-center">{{ $t('confirmSynch') }}</h4>
                    <br />
                    </div>
                </modal>
            </CRow>
            <div>
                <CModal  size="xl" :show.sync="showDetailProduct">
                    <CCard v-if="showDetailProduct">

                        <CCardBody>
                            <div class="p-0 tabs" :style="{ '--tab-count': tabCount }">
                                <input type="radio" id="tab0" name="tab-control" checked />
                                <input type="radio" id="tab1" name="tab-control" />
                                <input type="radio" id="tab2" name="tab-control" />
                                <input type="radio" id="tab3" name="tab-control" />
                                <input type="radio" id="tab4" name="tab-control" />
                                <input type="radio" id="tab5" name="tab-control" />

                                <ul class="ulbar font-weight-normal text-dark"  >
                                    <li
                                            v-for="(channel, index) in deliveyIsActive"
                                            :key="channel.PRUType.id"
                                            :title="channel.PRUType.name"
                                        >
                                        <label :for="'tab' + index" role="button">
                                            <img
                                                :src="getImgDelivery(channel.PRUType.id)"
                                                alt="Channel Icon"     
                                                style="width: 24px; height: 24px;"
                                            />
                                            <br />
                                            <span>  {{ channel.PRUType.name }}</span>
                                            </label>
                                        </li>
                                </ul>
                                <div class="slider">
                                    <div class="indicator"></div>
                                </div>
                                <div class="content">
                                    <section class="text-dark mt-5"  v-for="(channel) in deliveyIsActive"
                                        :key="channel.PRUType.id">
                                        <template>
                                            <h4>{{ $t('information') }}   
                                                <img
                                                    :src="getImgDelivery(channel.PRUType.id)"
                                                    alt="Channel Icon"     
                                                    style="width: 26px; height: 26px;"
                                                />
                                            </h4>
                                            <div class="row">   
                                                <div class="col-lg-3">
                                                    <div class="row">
                                                        <div class="col-lg-12" v-if="itemDetail.ProductPLU !== undefined" >
                                                            <img v-if="itemDetail.ProductPLU.remoteImagePath"
                                                                :src="itemDetail.ProductPLU.remoteImagePath" class="img-fluid rounded"
                                                                style="border-radius: 3px; width:auto" />
                                                            <CButton v-else style="width:100%; height: 200px;"
                                                                :style="{ backgroundColor: itemDetail.indexColor }"
                                                                class="img-fluid rounded">
                                                                <CIcon>
                                                                    <i class="fas fa-square"></i>
                                                                </CIcon>
                                                            </CButton>
                                                        </div>
                                                        <div class="col-lg-12 mt-2" v-if="itemDetail.ProductPLU !== undefined" >
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <h5 class="font-weight-bold">{{ itemDetail.ProductPLU.name }}</h5>
                                                                    <p class="mb-0 font-weight-bold">  {{ $t("price") }} <CIcon name="cil-money"  /> {{channel.exceedUc }}</p>
                                                                    <p class="mb-0" v-if="itemDetail.data.ProductSKU !==undefined " >{{ $t("category") }} : {{ itemDetail.data.ProductSKU.category.name }}</p>

                                                                    <p v-if="itemDetail.ProductPLU.unit !==undefined ">{{ $t("unit") }} : {{ itemDetail.ProductPLU.unit.name }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-9">
                                                    <CAlert class="mr-4  " color="secondary">
                                                        <CCol md="12" class="form-group row mt-1 mb-1">
                                                            <label id="productInformationCategoryLabel"
                                                            class="col-sm-3 col-form-label text-left text-dark w-100 w-sm-auto">
                                                            <h5>{{ $t('salesHours') }}</h5> 
                                                            </label>
                                                            <div id="productInformationCategoryInputContainer" class="col-sm-9">
                                                            <Multiselect id="productPriceSellingTimeSelect" 
                                                                v-model="channel.sellingTime" @input="getUpdateProduct(itemDetail, channel)"   
                                                                :options="sellingTimelist" class="cursor "
                                                                :placeholder="$t('All Days')" label="name" track-by="value"></Multiselect>
                                                                <CAlert
                                                                    id="addSalesHoursSettingInfoAlert"
                                                                    color="info" class="d-inline-block col-12 mt-2"  style="font-size: 14px; padding: 8px 12px"
                                                                    >
                                                                    <i class="fi fi-rr-info mr-1"></i>
                                                                    {{ $t("setSellingHoursForProduct") }}
                                                                </CAlert>
                                                            </div>
                                                        </CCol>
                                                        <hr />
                                                        <CCol md="12" class="form-group row mt-1 mb-1">
                                                            <label id="productInformationCategoryLabel" 
                                                            class="col-sm-3 col-form-label text-left text-dark w-100 w-sm-auto">
                                                            <h5>{{$t('manageStock')}}</h5>
                                                            </label>
                                                            <div id="productInformationCategoryInputContainer" class="col-sm-9">
                                                                <p class="mb-0">{{$t('maxStock')}}</p>
                                                                <CInput
                                                                    id="maxStock"
                                                                    v-model="channel.maxStock"
                                                                    type="number"
                                                                    :placeholder="$t('enterStockOndelivery')"
                                                                    class="text-black"
                                                                    @blur="validateAndSave(channel)" 
                                                                    @input="validateInteger('maxStock', channel)"
                                                                />
                 
                                                                <p class="mb-0">{{ $t('replenishment') }}</p>                                                          
                                                                <Multiselect id="productPriceSellingTimeSelect" 
                                                                    v-model="channel.replenishment"      @input="updateByProduct(channel)"
                                                                    :options="optionsStock" class="cursor "
                                                                    :placeholder="$t('ทันที')" label="name" track-by="value"></Multiselect>
                                                               <CAlert
                                                                    id="addSalesHoursSettingInfoAlert"
                                                                    color="info" class="d-inline-block col-12 mt-2"  style="font-size: 14px; padding: 8px 12px"
                                                                >
                                                                    <i class="fi fi-rr-info mr-1"></i>
                                                                    {{ $t("deliveryStock") }}
                                                                </CAlert>
                                                                
                                                            </div>
                                                        </CCol>
                                                    </CAlert>
                                                </div>
                                            </div>
                                        </template>
                                    </section>
                                </div>
                            </div>         
                        </CCardBody>
                    </CCard>
                    <template #footer>
                    <div class="w-100 d-flex justify-content-center">
                        <CButton style="width: 100%;margin-left: 1cap;" @click="showDetailProduct = false" color="light">
                            <b>{{ $t("closed") }}</b>
                        </CButton>
                    </div>
                </template>
                </CModal> 
            </div>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import util from "@/util/util";
import pos from "@/services/local";
import Pagination from "@/containers/Pagination";
import Sortdata from "@/containers/SortData";
import permis from "@/util/permission";
import Multiselect from "vue-multiselect";
import "@/util/tabs.css";
import moment from 'moment'



export default {
    components: {
        Pagination,
        Sortdata,
        Multiselect
    },
    data() {
        return {
            isEcommercePage: false,
            data: [],
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: 10,
                itemCount: 0,
            },
            meta_data_optional: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: 10,
                itemCount: 0,
            },
            loadingButton: true,
            loading: true,
            actionitem: false,
            sortdata: "ProductPLU.name",
            countProduct: 0,
            sort_value: {
                name: "ProductPLU.name",
                updated_at: "created_at",
                category: "ProductSKU.category.name",
          
            },
            view: "",
            keyword: this.$route.query.keyword || "",
            searchBy: this.$route.query.searchBy || "",
            salesChannel: [],
            confirmSynchModal : false,
            alertError : false,
            textError : '',
            textMessage : '',
            uploadProgress: 0,
            synchSuccess : false,
            synchSuccessCounter : 0,
            status: "",                // Menu sync status
            statusCheckInterval: null ,// Interval for status checking
            synchStatusText: "",       // Text to display for sync status
            synchFailDescription : "",
            sellingTimelist : [],
            sellingTime : null,
            dropdownVisible: false,
            showDetailProduct :  false,
            itemDetail : {},
            deliveyIsActive : [],
            activeTab: 0, // Tracks the active tab
            tabCount : 1,
            showView : 'product',
            lastSyncDate : new Date(),
            productoption : [],

        };
    },
    computed: {
        ...mapGetters(["shops", "users", "date"]),
        statusColor() {
            if (this.status === 'QUEUEING') {
                return 'warning';
            } else if (this.status === 'PROCESSING') {
                return 'warning';
            } else if (this.status === 'SUCCESS') {
                return 'success';
            }else if (this.status === 'fail') {
                return 'danger';
            } else {
                return 'danger'; // Default color for unknown status
            } 
        },
        optionsStock() {

            return [
                { name: this.$i18n.t('immediately'), value: null },
                // { name: this.$i18n.t('everyDay'), value: "DAY" },
                // { name: this.$i18n.t('everyWeek'), value: "WEEK" },
            ];
        },
        isPermission() {
            return true
        },
        isEditData() {
            const value = permis.findPermissionEdit("product", this.$route.path);
            return value; 
        },
        editItem() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
            return shop.editableItem || false;
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId;
            },
            set(newValue) {
                return this.$store.dispatch("setShop", newValue);
            },
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
            return shop.uid;
        },
        sortAttr: {
            get() {
                let sortdata = this.sortdata;
                if (sortdata == 'name'){
                    return 'ProductPLU.name'
                }else{
                    return sortdata;
                }
            },
            set(newValue) {
                return newValue;
            },
        },
        itempage() {
            return this.meta_data.current_page * 50 - 50;
        },
        findItemDelivery(item) {
            return item
        },
        items() {
            let data = this.data;
            let detail = [];
            let categoryName = "-";
            let onhandQty = 0;
            let PLUCode = "-";
            let img = "";
            let unit = "-";
            let source = '-'

            for (let i = 0; i < data.length; i++) {
                if (data[i].skuType === "SV" || data[i].skuType === "BOM") {
                    onhandQty = "non-stock products";
                } else {
                    if (data[i].onhandQty !== undefined) {
                        onhandQty = data[i].onhandQty;
                    }
                }
                if (data[i].ProductSKU !== undefined) {
                    if (data[i].ProductSKU.category !== undefined) {
                        if (data[i].ProductSKU.category.name !== undefined) {
                            categoryName = data[i].ProductSKU.category.name;
                        }
                    }
                }

                if (data[i].category !== undefined) {
                    if (data[i].category.source !== undefined) {
                        source = data[i].category.source;
                    } else {
                        source = '-'
                    }
                }
                if (data[i].productPLU !== undefined) {
                    if (data[i].productPLU.PLUCode !== undefined) {
                        PLUCode = data[i].productPLU.PLUCode;
                    }
                }
                if (data[i].ProductPLU.remoteImagePath === undefined) {
                    img = undefined;
                } else if (data[i].ProductPLU.remoteImagePath == "") {
                    img = undefined;
                } else {
                    img = data[i].ProductPLU.remoteImagePath;
                }

                if (data[i].unit !== undefined) {
                    if (data[i].unit.name !== undefined) {
                        unit = data[i].unit.name;
                    }
                }
                let isMenu = false;
                if (data[i].isMenu != undefined) {
                    isMenu = data[i].isMenu;
                }
                let stdCost = data[i].stdCost !== undefined ? data[i].stdCost : 0;

                detail.push({
                    SKUName: data[i].ProductPLU.name,
                    unit: unit,
                    onhandQty: util.convertNumber(onhandQty),
                    price: util.convertCurrency(data[i].SKUPrice),
                    productSKUObjectId: data[i].objectId,
                    PLUCode: PLUCode,
                    category: categoryName,
                    isOnScreen: data[i].isOnScreen,
                    favorite: data[i].favorite,
                    skuType: data[i].skuType,
                    objectId: data[i].objectId,
                    indexColor: util.generateColor(data[i].indexColor),
                    remoteImagePath: img,
                    vatType: data[i].vatType,
                    sortPrice: data[i].SKUPrice,
                    created_at: data[i].created_at,
                    isMenu: isMenu,
                    stdCost: stdCost,
                    source: source
                });
            }
            return detail;
        },
        queryparams() {
            return {
                sort: this.sortdata,
                page: this.meta_data.current_page,
                view: this.showView,
                searchBy: this.searchBy,
                keyword: this.keyword,
            };
        },

    },
    created() {
        this.getSalesChannelsSetting();
        // this.getProduct()
        this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
        this.getManageSaleHours()
        this.getData()
        // this.getProductOption()
        this.CheckLastSyncStatus()

    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getSalesChannelsSetting()
            }
        },
        showView(newVal, oldVal){        
            if(newVal !== oldVal){
                this.keyword = ''
                if(newVal === 'product'){
                    this.getProduct()
                }
                if(newVal === 'optional'){
                    this.getProductOption()
                }
            }
        }
    },
    mounted() {

    },
    methods: {
        ...util,
        getData(){
            if (this.showView === 'product') {
                this.getProduct()
            } else {
                this.getProductOption()
            }
        },
        showDetailProductEvent(item) {
            this.itemDetail = item
            const delivery =    item.delivery.filter((channel) => channel.isActive);
            this.deliveyIsActive = delivery
            this.tabCount = delivery.length
            
            this.activeTab = 0
            this.$nextTick(() => {
            const firstTab = document.getElementById('tab0');
                if (firstTab) {
                    firstTab.checked = true;
                }
            });
            this.showDetailProduct = true
        },
        getUpdateProduct(item, delivery) {
            const data = {
                productPRU: {
                    objectId: delivery.objectId,
                    id: delivery.id,
                },
            };

            Object.assign(data, {
                sellingTimeId: delivery.sellingTime.id || null, // Default to null if undefined
            });

            const headers = {
                shopObjectId: this.shopObjectId,
            };
            pos({
                method: 'PATCH',
                headers: headers,
                url: '/api/v3.0/grab/menu/update',
                data: data,
            })
            .then(() => {
                // Update UI state on success
                // this.salesChannel[index].isEdit = false;
            })
            .catch((error) => {
                console.error('Error updating product price by channel:', error);
            });
        },
        getImgDelivery(id) {
            return util.getDeliveryImg(id);
        },
        lastSync() {
            let date = this.lastSyncDate;
            return moment(date).format('DD/MM/YYYY HH:mm')

        },
        toggleDropdown(item) {
            const sellingTime = this.data.findIndex((time) => time.ProductPLU.objectId === item.ProductPLU.objectId)
            this.data[sellingTime].isEdit = !this.data[sellingTime].isEdit;
        },
        findReplenishmentByValues(replenishment) {
            const time = this.optionsStock.find((item) => item.value === replenishment);

            return time ? time : null;
        },
        validateInteger(key, channel) {
            const sanitizedValue = channel[key].replace(/[^0-9]/g, '');
            channel[key] = sanitizedValue
        },
        validateAndSave(channel) {
            this.validateInteger('maxStock', channel);
            this.updateByProduct(channel);
        },
        updateByProduct(delivery) {
            const data = {
                productPRU: {
                    objectId: delivery.objectId,
                    id: delivery.id,
                },
            };
    
            if (typeof delivery.maxStock !== 'number' || isNaN(delivery.maxStock)) {
                delivery.maxStock = util.currencyStringToNumber(delivery.maxStock);
            }
            Object.assign(data, {
                maxStock :  delivery.maxStock,
                replenishment : delivery.replenishment.value || null, // Default to null if undefined
            });
            

            const headers = {
                shopObjectId: this.shopObjectId,
            };            
            pos({
                method: 'PATCH',
                headers: headers,
                url: '/api/v3.0/grab/menu/update',
                data: data,
            })
            .then(() => {
                // Update UI state on success
                // this.salesChannel[index].isEdit = false;
            })
            .catch((error) => {
                console.error('Error updating product price by channel:', error);
            });
        },
        findSellingTimeById(sellingTimeId) {
            const time = this.sellingTimelist.find((item) => item.id === sellingTimeId);

            return time ? time : null;
        },
        getManageSaleHours() {
            this.loadingButton = false;

            const shopObjectId = this.shopObjectId; 
            const headers = { shopObjectId }; 

            pos.get('/api/v3.0/sellingchannel/sellingtime', { headers })
                .then((res) => {
                    if (res.status === 200) {
                        let data = res.data.data
                        if(data.length > 0){
                            this.sellingTimelist = data
                        }


                    } else {
                        console.error("Failed to fetch sales hours data. Status:", res.status);
                        this.data = [];
                    }
                })
                .catch((error) => {
                    console.error("Error fetching sales hours data:", error);
                    this.data = [];
                })
                .finally(() => {
                    this.loading = true;
                    this.loadingButton = true;
                });
        },
        updateAnabledByObtion(delivery, item){

            let availableStatus = 'UNAVAILABLE'
            if (delivery.enabled) {
                availableStatus = 'AVAILABLE'
            } else {
                availableStatus == 'UNAVAILABLE'  
            }
             
            const data = {
              optionItem: {
                  objectId: item.objectId,
                  id:item.id
              },
              name: item.name,
              channel :  delivery.channelKey,
              availableStatus: availableStatus
            }                      
            const headers = { shopObjectId: this.shopObjectId }
            
            pos({
                method: 'PATCH',
                url: '/api/v3.0/option/item/update',
                data: data,
                headers: headers,
            })
            .then(
                setTimeout(() => {
                    this.loadingSaveButton = false
                }, 1000)
            ).catch((error) => {
                console.log(error)
                this.loadingSaveButton = false
            })
            
        },
        CheckLastSyncStatus(){
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'GET',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync/status',
            })
            .then((res) => {
                this.lastSyncDate = res.data.data.updatedAt;

            })
            .catch((error) => {
                console.error(error);
        
            });
        },

        menuSyncStatus(){
            this.loading = false
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'GET',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync/status',
            })
            .then((res) => {
                if(res.status === 200){
                    this.status = res.data.data.status;

                    if (this.status === 'QUEUEING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchQueueing')  
                        // alert('The menu synchronization is currently in QUEUEING. Please wait...');
                    } else if (this.status === 'PROCESSING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchProcessing')  
                        // alert('The menu synchronization is in progress. Please be patient.');
                    } else if (this.status === 'SUCCESS') {
                        this.synchSuccessCounter  = 5
                        this.synchSuccess = true,
                        this.synchStatusText = this.$i18n.t('synchSuccess') 
                        this.lastSyncDate = res.data.data.updatedAt;
                        // alert('The menu synchronization is complete!');
                        // Stop the polling logic if needed
                        this.loading = true
                        clearInterval(this.syncInterval);
                    }else if  (this.status === 'FAILED') {
                        this.status = "fail";
                        this.synchSuccessCounter  = 5
                        this.synchStatusText = this.$i18n.t('synchFail') 
                        this.statusDescription = this.$i18n.t('synchFailDescription')
                        clearInterval(this.syncInterval);
                        // alert('An error occurred while checking the sync status. Please try again.');
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
            });
        },
        
        synchronizeMenuwithGrab(){
            this.loading = false
            this.uploadProgress = 60
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }

            pos({
                method: 'POST',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync',
            })
            .then((res) => {                
                if(res.data.error.code === 0){
                    this.uploadProgress = 0
                    this.loading = true
                    this.confirmSynchModal = false
                    this.menuSyncStatus();
                    this.startSyncCheck();
                }else{
                    this.loading = true
                    this.confirmSynchModal = false
                    this.status = "fail";
                    this.synchSuccessCounter  = 5
                    this.synchStatusText =this.$i18n.t('synchFail') 
                    this.statusDescription =  res.data.error.message
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
                // alert('Failed to initiate menu synchronization. Please try again.');
            })
        },
        startSyncCheck() {
            // Initialize polling every 10 seconds until status is SUCCESS
            this.syncInterval = setInterval(() => {
                this.menuSyncStatus();
                if (this.status === 'SUCCESS') {
                    clearInterval(this.syncInterval);
                }
            }, 10000); // 10 seconds
        },
        getSort( keyword) {
            this.keyword = keyword
            if (this.showView === 'product') {
                this.getProduct()
            } else {
                this.getProductOption()
            }
        },
        updateAnabledByProduct(item, index){      
            let data = 
                {
                    productPRU : {
                        id: item.id,
                        objectId: item.objectId,
                    },
                    enabled: item.enabled
                }

            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }

            pos({
                method: 'PATCH',
                headers :headers,
                url: '/api/v3.0/grab/menu/enable',
                data: data,
            })
            .then(() => {
                
            })
            .catch((error) => {
            console.error(error)
            })
        },
        getSalesChannelsSetting() {
            let params = {
                shopObjectId: this.shopObjectId,
                type: 'ONLINE'
            }
            const headers = { 
                shopObjectId: this.shopObjectId 
            }
            pos({
                url: "/api/v3.0/sellingchannel/establish",
                params: params,
                headers : headers,
                method: "get",
            }).then((res) => {

                const data = res.data?.data || []; 
                this.salesChannel = data
                    .map((item) => ({
                        objectId: item.objectId,
                        gpPercent: item.gpPercent,
                        id: item.id,
                        name: item.name,
                        orderIndex: item.orderIndex,
                        typeGroup: item.typeGroup,
                        img: util.getDeliveryImg(item.id), // Get image URL
                    }))
                    .sort((a, b) => a.id.localeCompare(b.id)); // Sort by `id` in ascending order
                
                this.getData()
                // this.getProductOption()
            });
        },
        setSort(value) {
            this.sortdata = value;
            if (this.showView === 'product') {
                this.getProduct()
            } else {
                this.getProductOption()
            }
        },
        setSearch(searchBy, keyword) {
            this.keyword = keyword;
            this.searchBy = searchBy;
            this.getProduct();
        },
        getProduct(page = this.$route.query.page || 1) {
            this.searchLoadingButton = false;
            this.loadingButton = false;
            const uid = `${localStorage.getItem("shopsUid")}`;
            const shopObjectId = this.shopObjectId;
            const headers = { shopObjectId: shopObjectId };

            let sortOrder = "";
            if (this.sortdata == "sortByNumMin") {
                sortOrder = "asc";
            } else if (this.sortdata == "sortByNumMax" || this.sortdata == "created_at") {
                sortOrder = "desc";
            } else {
                sortOrder = "asc";
            }

            let params = {
                shopObjectId: shopObjectId,
                page: page,
                searchWord : this.keyword,
                sortAttr: this.sortAttr,
                sortOrder: sortOrder,
                limit: this.meta_data.limit,
                type :  'ONLINE'
            };
            // Add search attribute.
            if (this.searchBy !== "") {
                params["searchVal"] = this.keyword;
                params["searchAttr"] = this.searchBy;
            }
            pos({
                url: "/api/v3.0/sellingchannel/channel/product",
                params: params,
                headers: headers,
                method: "GET",
            }).then((res) => {
         
                
                let data = res.data.data

                let detail = []
                for (let i = 0; i < data.length; i++) {
                    let indexColor = util.generateColor(1)
                    let delivery = []
                    let salesChannel = this.salesChannel
                    if (data[i].RRU !== undefined) {
                        let del = data[i].RRU
                        for (let j = 0; j < salesChannel.length; j++) {
                            let channelData = del.find(item => item.PRUType.id === salesChannel[j].id)
                            if (channelData !== undefined) {
                                let enabled = true
                                let sellingTimeId = null
                                let sellingTime = ''
                                let maxStock = 0
                                let replenishment = ''
                                
                                if (channelData.availableStatus !== undefined) {
                                    if (channelData.availableStatus == 'UNAVAILABLE') {
                                        enabled = false
                                    } else if (channelData.availableStatus == 'AVAILABLE') {
                                        enabled = true
                                    }
                                }

                                if(channelData.replenishment !== undefined){
                                    replenishment = this.findReplenishmentByValues(channelData.replenishment)
                                }else{
                                    replenishment =  {
                                        name: this.$i18n.t('immediately'),
                                        value: null
                                    }
                                }

                                if(channelData.maxStock !== undefined){
                                    maxStock = channelData.maxStock
                                }else{
                                    maxStock = 0
                                }


                                if(channelData.sellingTimeId !== undefined){
                                    sellingTimeId = channelData.sellingTimeId
                                    sellingTime = this.findSellingTimeById(sellingTimeId)
                                }
                                
                                // if(channelData.PRUType.id !== '1' && channelData.PRUType.id !== '3' ){
                                    delivery.push({
                                        isActive: true,
                                        objectId: channelData.objectId,
                                        PRUType: channelData.PRUType,
                                        exceedUc: util.convertCurrency(channelData.exceedUc),
                                        id: channelData.id,
                                        packQty: channelData.packQty,
                                        enabled: enabled,
                                        sellingTimeId : sellingTimeId,
                                        sellingTime  : sellingTime, 
                                        replenishment : replenishment,
                                        maxStock : maxStock
                                    })
                            } else {
                                delivery.push({
                                    isActive: false,
                                })
                            }

                        }
                    } else {
                        delivery = salesChannel
                    }

                    if (data[i].ProductPLU.indexColor !== undefined) {
                        indexColor = util.generateColor(data[i].ProductPLU.indexColor)
                    }
                    
                    detail.push({
                        ProductPLU: data[i].ProductPLU,
                        ProductSKU : data[i].ProductSKU,
                        indexColor: indexColor,
                        data: data[i],
                        delivery: delivery,
                        isEdit :  false
                    })

                }

                this.data = detail;
                if (res.data.paginate.items == undefined) {
                    this.countProduct = 0;
                    this.meta_data.items = 0;
                } else {
                    this.countProduct = res.data.paginate.items;
                    this.meta_data.items = res.data.paginate.items;
                }
                this.meta_data.last_page = res.data.paginate.pageCount;
                this.meta_data.current_page = res.data.paginate.currentPage;
                this.meta_data.itemCount = res.data.paginate.itemCount;
                this.meta_data.limit = res.data.paginate.perPage;
                this.countlist = res.data.length;
                this.searchLoadingButton = true;
                this.loadingButton = true;
            });
        },
        getProductOption(page = 1) {
            this.searchLoadingButton = false
            this.loadingButton = false
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            const params = { 
                page: page, 
                limit: 50, 
                shopObjectId: shopObjectId,
                searchAttr : 'name',
                searchVal : this.keyword
            }

            const headers = { shopObjectId: shopObjectId }
            pos({
                url: '/api/V1.0/' + uid + '/option/item/get/',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                this.data = res.data.data
                let  productoption = res.data.data
                let salesChannel = this.salesChannel

                let detail = [];

                for (let i = 0; i < productoption.length; i++) {
                let delivery = []; // Reset delivery for each product
                const channelKeyMap = {
                    101: "grabfood",
                    102: "lineman",
                    103: "foodpanda",
                    104: "shopeefood",
                    105: "robinhood",
                    106: "truefood",
                };
                for (let j = 0; j < salesChannel.length; j++) {
                    const channelId = salesChannel[j].id;
                    const channelKey = channelKeyMap[channelId]; // Map the id to the correct property key
  
                    if (productoption[i][channelKey]) {
                        const channelData = productoption[i][channelKey];

                        // Handle availableStatus and enabled
                        const availableStatus = channelData.availableStatus || "UNAVAILABLE";
                        const enabled = availableStatus === "AVAILABLE";

                        // Handle price
                        const price = channelData.deliveryOnDemand || 0;

                        // Add to delivery array
                        delivery.push({
                            id: channelId,
                            name: salesChannel[j].name,
                            channelKey  : channelKey,
                            enabled: enabled,
                            price: util.convertCurrency(price),
                            availableStatus: availableStatus,
                        });
                    } else {
                        delivery.push({
                            id: channelId,
                            name: salesChannel[j].name,
                            enabled: false,
                            price: '0.00',
                            availableStatus: "UNAVAILABLE",
                            channelKey  : channelKey,
                        });
                    }
                    }

                // Add the product with its delivery details to the detail array
                detail.push({
                    id: productoption[i].id,
                    objectId: productoption[i].objectId,
                    name: productoption[i].name,
                    delivery: delivery,
                });
                }
                this.productoption = detail
    
            
                 if (res.data.paginate.items == undefined) {
                    this.countProduct = 0;
                    this.meta_data_optional.items = 0;
                } else {
                    this.countProduct = res.data.paginate.items;
                    this.meta_data_optional.items = res.data.paginate.items;
                }
                this.meta_data_optional.last_page = res.data.paginate.pageCount;
                this.meta_data_optional.current_page = res.data.paginate.currentPage;
                this.meta_data_optional.itemCount = res.data.paginate.itemCount;
                this.meta_data_optional.limit = res.data.paginate.perPage;

                this.countlist = res.data.length
                this.searchLoadingButton = true
                this.loadingButton = true
        })
        },
    },

};
</script>
<style  scoped >
.form-control {
    cursor: pointer;
}

.custom-select {
    cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
    cursor: default;
}

.view-list {
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 1px;
}

.view-icon:active {
    color: #29b289;
    background-color: white;
}

label.btn.form-check-label.col-lg-6.btn-ghost-secondary.view-icon.active {
    background-color: white;
    color: #29b289;
}

.list {
    box-shadow: 0 0 0 1px #e5e5e5;
}

.list:hover {
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
}

.posti {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 16px;
    background-color: #dcdce0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.rd {
    aspect-ratio: 4 / 3;
    object-fit: cover;
}

.btn-reset {
    box-shadow: 0 0 0 1px #e5e5e5;
}

.btn-reset:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.display {
    position: relative;
    text-align: center;
    color: white;
}

.display-type {
    font-size: 16px !important;
    position: absolute;
    top: 3px;
    right: 5px;
}

.display-type-favorite {
    position: absolute;
    bottom: 3px;
    right: 5px;
    font-size: 15px !important;
}

.label-product {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;
    /* fallback */
    max-height: 32px;
    /* fallback */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
}

.lo-label {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}

.lo-label-unit {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}

.unit-label {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: darkslategray;
    text-overflow: ellipsis;
    /* เพิ่ม ... จุดจุดจุดท้ายสุด */
}

.icon-css {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 4px;
    padding-left: 0px;
}

.limit-2 {
    line-height: 20px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.limit-1 {
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.name-product {
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.badge-primary:hover {
    color: #fff;
    background-color: #478dd5;
    border-color: #3c86d3;
}
.badge-dark:hover {
    color: #fff;
    background-color: #212631;
    border-color: rgb(24.75, 28.5, 36.75);
}
.tabs {
  position: relative;
  width: 100%;
}


.tabs .slider {
    position: relative;
    width: calc(100% / var(--tab-count)); 
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.time-picker-header {
    height: 35px;
    overflow: hidden;
    border: 1px solid #ebedef;
    background-color: #f6f6f6;
}
.modal-item-detail{
  position: fixed !important;
  right: 24px !important;
  width: 30%;
  align-items: normal;
}

.tabs .btn-tab {
    position: relative;
    width: calc(100% / var(--tab-count)); 
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.btn-tab {
    position: relative;
    width: calc(100% / var(--tab-count)); 
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
