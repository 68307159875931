<template>
  <div class="sort">
    <div class="btn-group btn-group-toggle row align-items-center" data-toggle="buttons" style="margin-left: 5px;">
      <span class="p font-weight-normal" style="padding: 8px;">
        {{ $t('sortBy') }}
      </span>
      <label v-if="sort_value.name != undefined"
        class="btn btn-ghost-success form-check-label col-4 col-sm-2 btncss align-self-center"
        :class="{ active: value === sort_value.name }" style="border-radius: 3px; margin-top: 0px;">
        <input type="radio" name="options" id="option1" autocomplete="off"
          class="custom-control-input cursor align-self-center " value="sort_value.name"
          :active="value === sort_value.name" :checked="value === sort_value.name" @change="getSort(sort_value.name)" />
        {{ $t('name') }}
      </label>
      <label v-if="sort_value.PLUCode != undefined"
        class="btn btn-ghost-success form-check-label col-4 col-sm-2 align-self-center btncss"
        :class="{ active: value === sort_value.PLUCode }" style="border-radius: 3px; margin-top: 0px;">
        <input type="radio" name="options" id="option1" autocomplete="off"
          class="custom-control-input success cursor align-self-center" value="sort_value.PLUCode"
          :active="value === sort_value.PLUCode" :checked="value === sort_value.PLUCode"
          @change="getSort(sort_value.PLUCode)" />
        {{ $t('pluCode') }}
      </label>
      <label v-if="sort_value.updated_at != undefined"
        class="btn btn-ghost-success form-check-label col-4  col-sm-2  btncss align-self-center"
        :class="{ active: value === sort_value.updated_at }" style="border-radius: 3px; margin-top: 0px;">
        <input type="radio" name="options" id="option1" autocomplete="off"
          class="custom-control-input success cursor align-self-center" value="sort_value.updated_at"
          :active="value === sort_value.updated_at" :checked="value === sort_value.updated_at"
          @change="getSort(sort_value.updated_at)" />
        {{ $t('last') }}
      </label>
      <label v-if="sort_value.category != undefined"
        class="btn btn-ghost-success form-check-label col-4  col-sm-2  btncss align-self-center"
        :class="{ active: value === sort_value.category }" style="border-radius: 3px; margin-top: 0px;">
        <input type="radio" name="options" id="option2" autocomplete="off"
          class="custom-control-input success cursor align-self-center" value="sort_value.category"
          :active="value === sort_value.category" :checked="value === sort_value.category"
          @change="getSort(sort_value.category)" />
        {{ $t('category') }}
      </label>
      <label v-if="sort_value.skuType != undefined" class="btn btn-ghost-success form-check-label col-4  col-sm-2  btncss"
        :class="{ active: value === sort_value.skuType }" style="border-radius: 3px; margin-top: 0px;">
        <input type="radio" name="options" id="option1" autocomplete="off" class="custom-control-input success cursor"
          value="sort_value.skuType" :active="value === sort_value.skuType" :checked="value === sort_value.skuType"
          @change="getSort(sort_value.skuType)" />
        {{ $t('Product type') }}
      </label>
      <div class="dropdown align-items-center" v-if="sort_value.sortByNumMin != undefined">
        <button class="dropbtn btn-ghost-success" :class="{
          active:
            value === sort_value.sortByNumMin ||
            value === sort_value.sortByNumMax,
        }">
          <CRow>
            <CCol col="10" lg="10" class="text-left">
              <p style="margin: 2px; padding: 4px 6px" v-if="value === sort_value.name ||
                value === sort_value.updated_at ||
                value === sort_value.category ||
                value === sort_value.onhandQtyMin ||
                value === sort_value.onhandQtyMax ||
                value === sort_value.skuType ||
                value === sort_value.PLUCode ||
                value === sort_value.sortBySalesMax ||
                value === sort_value.sortBySalesMin ||
                value === sort_value.sortByBillAmountMax ||
                value === sort_value.sortByBillAmountMin
                ">
                {{ $t('price') }}
              </p>
              <p v-else-if="value === sort_value.sortByNumMin" class="select-true">
                {{ $t('sortByNumMin') }}
              </p>
              <p v-else-if="value === sort_value.sortByNumMax" class="select-true">
                {{ $t('sortByNumMax') }}
              </p>
            </CCol>
            <CCol col="2" lg="2" class="text-right" style="display: flex; align-items: center;">
              <i class="fi fi-rr-angle-small-down"></i>
            </CCol>
          </CRow>
        </button>
        <div class="dropdown-content">
          <a @click="getSort(sort_value.sortByNumMin)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em" v-if="value === sort_value.sortByNumMin">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortByNumMin') }}
          </a>
          <a @click="getSort(sort_value.sortByNumMax)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em" v-if="value === sort_value.sortByNumMax">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortByNumMax') }}
          </a>
        </div>
      </div>
      <div class="dropdown align-items-center" v-if="sort_value.onhandQtyMax != undefined">
        <button class="dropbtn btn-ghost-success" :class="{
          active:
            value === sort_value.onhandQtyMin ||
            value === sort_value.onhandQtyMax,
        }">
          <CRow>
            <CCol col="10" lg="10" class="text-left">
              <p style="margin: 2px; padding: 4px 6px" v-if="value === sort_value.name ||
                value === sort_value.updated_at ||
                value === sort_value.category ||
                value === sort_value.sortByNumMin ||
                value === sort_value.sortByNumMax ||
                value === sort_value.skuType ||
                value === sort_value.PLUCode ||
                value === sort_value.sortBySalesMax ||
                value === sort_value.sortBySalesMin ||
                value === sort_value.sortByBillAmountMax ||
                value === sort_value.sortByBillAmountMin
                ">
                {{ $t('onhandQtySort') }}
              </p>
              <p v-else-if="value === sort_value.onhandQtyMin" class="select-true">
                {{ $t('onhandQtyMin') }}
              </p>
              <p v-else-if="value === sort_value.onhandQtyMax" class="select-true">
                {{ $t('onhandQtyMax') }}
              </p>
            </CCol>
            <CCol col="2" lg="2" class="text-right" style="display: flex; align-items: center;">
              <i class="fi fi-rr-angle-small-down"></i>
            </CCol>
          </CRow>
        </button>
        <div class="dropdown-content">
          <a @click="getSort(sort_value.onhandQtyMin)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em" v-if="value === sort_value.onhandQtyMin">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('onhandQtyMin') }}
          </a>
          <a @click="getSort(sort_value.onhandQtyMax)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em" v-if="value === sort_value.onhandQtyMax">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('onhandQtyMax') }}
          </a>
        </div>
      </div>

      <div class="dropdown align-items-center"  v-if="sort_value.sortBySalesMin != undefined">
        <button class="dropbtn btn-ghost-success" style="width: 170px;" 
        :class="{
          active:
            value === sort_value.sortBySalesMin ||
            value === sort_value.sortBySalesMax,
        }">
          <CRow>
            <CCol col="10" lg="10" class="text-left">
              <p style="margin: 2px; padding: 4px 6px; width: 163px; border-style:none;" v-if="value === sort_value.name ||
                value === sort_value.updated_at ||
                value === sort_value.category ||
                value === sort_value.onhandQtyMin ||
                value === sort_value.onhandQtyMax ||
                value === sort_value.skuType ||
                value === sort_value.PLUCode ||
                value === sort_value.sortByNumMax ||
                value === sort_value.sortByNumMin ||
                value === sort_value.sortByBillAmountMax ||
                value === sort_value.sortByBillAmountMin
                ">
                {{ $t('sales') }}
              </p>
              <p v-else-if="value === sort_value.sortBySalesMin" class="select-true">
                {{ $t('sortBySalesMin') }}
              </p>
              <p v-else-if="value === sort_value.sortBySalesMax" class="select-true">
                {{ $t('sortBySalesMax') }}
              </p>
            </CCol>
            <CCol col="2" lg="2" class="text-right" style="display: flex; align-items: center;">
              <i class="fi fi-rr-angle-small-down"></i>
            </CCol>
          </CRow>
        </button>
        <div class="dropdown-content" style="width: 170px;">
          <a @click="getSort(sort_value.sortBySalesMin)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em"
              v-if="value === sort_value.sortBySalesMin">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortBySalesMin') }}
          </a>
          <a @click="getSort(sort_value.sortBySalesMax)">
            <span class="text-right" style="margin-left: 0px; font-size: 0.75em"
              v-if="value === sort_value.sortBySalesMax">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortBySalesMax') }}
          </a>
        </div>
      </div>

      <div class="dropdown align-items-center"  v-if="sort_value.sortByBillAmountMin != undefined">
        <button class="dropbtn btn-ghost-success" style="width: 170px;" 
        :class="{
          active:
            value === sort_value.sortByBillAmountMin ||
            value === sort_value.sortByBillAmountMax,
        }" >
          <CRow>
            <CCol col="10" lg="10" class="text-left">
              <p style="margin: 2px; padding: 4px 6px; width: 163px; border-style:none;" 
              v-if="value === sort_value.name ||
                value === sort_value.updated_at ||
                value === sort_value.category ||
                value === sort_value.onhandQtyMin ||
                value === sort_value.onhandQtyMax ||
                value === sort_value.skuType ||
                value === sort_value.PLUCode ||
                value === sort_value.sortByNumMax ||
                value === sort_value.sortByNumMin ||
                value === sort_value.sortBySalesMax ||
                value === sort_value.sortBySalesMin
                ">
                {{ $t('billAmount') }}
              </p>
              <p v-else-if="value === sort_value.sortByBillAmountMin" class="select-true" style="margin: 2px; padding: 4px 6px; width: 163px;"
            >
                {{ $t('sortByBillAmountMin') }}
              </p>
              <p v-else-if="value === sort_value.sortByBillAmountMax" class="select-true" style="margin: 2px; padding: 4px 6px; width: 163px;">
            
                {{ $t('sortByBillAmountMax') }}
              </p>
            </CCol>
            <CCol col="2" lg="2" class="text-right" style="display: flex; align-items: center;">
              <i class="fi fi-rr-angle-small-down"></i>
            </CCol>
          </CRow>
        </button>
        <div class="dropdown-content" style="width: 170px;">
          <a @click="getSort(sort_value.sortByBillAmountMin)">
            <span class="text-right" style="margin: 2px; font-size: 0.75em; "
              v-if="value === sort_value.sortByBillAmountMin">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortByBillAmountMin') }}
          </a>
          <a @click="getSort(sort_value.sortByBillAmountMax)">
            <span class="text-right" style="margin:2px; font-size: 0.75em; "
              v-if="value === sort_value.sortByBillAmountMax">
              <i class="fi fi-rr-check"></i>
            </span>
            {{ $t('sortByBillAmountMax') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['sort_value', 'sortdata'],
  data() {
    return {
      value: this.sortdata,
    }
  },
  methods: {
    getSort(value) {
      this.value = value
      this.$emit('getSort', value)
    },
  },
}
</script>

<style src="../../src/assets/styles/sortCss.css"></style>

<style>



</style>